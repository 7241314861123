.reactions-block {
  background: #FFF;
  padding: 24px 15px;
  margin-top: 16px;
}

.reactions {
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 12px 30px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 15px;
    background: #F7F7F8;
    margin-bottom: 4px;
    // cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    color: var(--text-primary-light);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }

  &__left-box {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__right-box {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__svg {
    font-size: 32px;
  }
}

.toggle-span {
  display: flex;
  align-items: center;
}