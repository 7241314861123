.chart-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 16px;

  &__left-box {
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #27AE60;
      position: absolute;
      left: 0;
      top: calc(50% - 4px);
    }
  }

  &__right-box {
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #EF4062;
      position: absolute;
      left: 0;
      top: calc(50% - 4px);
    }
  }

  &__card {
    display: flex;
    align-items: center;
    border-radius: 12px;
    gap: 16px;
    padding: 16px;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
  }
}

.left {
  padding-left: 20px;
}

.who-registered {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  gap: 8px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}
