.message-error {
  margin-bottom: -13px;
  margin-top: 4px;
  left: 5px;
  color: #ff0000;
  font-size: 10px;
  letter-spacing: 1.5px;

  &::first-letter {
    text-transform: capitalize;
  }
}
