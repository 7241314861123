.form-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--text-color4);
  padding: 0 15px;

  span {
    color: var(--main-color);
  }
}

.form-subtitle-custom {
  display: block;
  margin-top: 30px;

  strong {
    color: var(--main-color);
    cursor: pointer;
  }
}

.password-options {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.password-strength {
	position: relative;
	display: flex;
	margin-top: 16px;
	gap: 2px;
	height: 2px;
  margin-top: -8px;

	&__line {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: 2px;
		background: var(--border-color-main);
		border-radius: 12px;
	}

	&__item {
		position: relative;
		display: none;
		width: 25%;
		height: 2px;
		border-radius: 12px;
		z-index: 2;

		&--weak {
			background: var(--text-color2);
		}

		&--strong {
			background: #00ba88;
		}

		&.active {
			display: block;
		}
	}
}

.check-pass {
  margin: 8px 0 16px;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__box {
    width: 15px;
    margin-right: 5px;
    opacity: 0;
  }

  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #B1B1B5;
  }
}

.active {
  opacity: 1;
}