.accordion__right-content .reactions-add__input-wrapper {
  margin-top: 0;
}

.appearance-title {
  color:  #070708;

  /* Medium/P3 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 28.8px */
}

.appearance-subtitle {
  max-width: 400px;
  color:  #858585;

  /* Regular/P4 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 25.2px */
}

.accordion__right-content .background__img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion__right-content .background__text-gray {
  margin-top: 10px;
  max-width: 210px;
}
