.header {
  &__logo {
    &--custom {
      margin-right: 110px;

      @media screen and (max-width: 563px) {
        margin-right: 0;
      }
    }
  }
}

.select {
  &__position {
    left: -210px;
    width: 220px;
  }
}

.countries-list {
  // svg {
  //   margin-left: 16px;
  //   margin-right: -5px;
  // }
}

.admin-notification {
  position: relative;
  margin-right: 24px;
}

.notification-red {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 16px;
  top: 0px;
  border-radius: 50%;
  background: #EF4062;
}

.select__drop-position {
  left: auto;
}
