.codes {
  padding: 15px;
  margin-top: 20px;

  &__info {
    display: flex;
    gap: 16px;
  }

  &__info-box {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #EEEEEF;
    background: #FFF;
    padding: 8px;
  }

  &__info-inner {
    display: flex;
    gap: 16px;
    border-radius: 8px;
    background: #F7F7F8;
    padding: 15px 24px;
  }

  &__info-text {
    color: var(--Color-Text-Gray, #929298);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
  }

  &__info-count {
    color: var(--Color-Text-Dark, #070708);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 44.8px */
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  &__revoce {
    display: flex;
    align-items: center;
  }

  &__revoce-text {
    color: #EF4062;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    margin-left: 8px;
  }
}
