.card-top {
  width: 100%;
  border-right: 1px solid #D9D9D9;
  margin-left: 24px;

  &__header {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    color: var(--text-primary-light, #070708);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 25.2px */
  }

  &__body {

  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
  }

  &__item {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 20px 35px 1fr 1fr;
  }

  &__td {
    display: flex;
    gap: 5px;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  &__btn {
    padding: 10px 16px;
    border-radius: 12px;
    border: 1px solid var(--line-line-light, #CFCFD2);
    background: var(--bg-accent-1-light, #FFF);

    color: var(--text-link, #0A49A5);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 25.2px */

    margin-top: 24px;
    max-width: max-content;
    height: 44px;
    align-self: flex-end;
  }

  &:last-child {
    border: none;
    padding-left: 24px;

    @media screen and (max-width: 1224px) {
      padding-left: 0;
    }
  }

  &:first-child {
    margin-left: 0;

    @media screen and (max-width: 768px) {
      border: none;

    }
  }

  @media screen and (max-width: 1224px) {
    margin-left: 0;

    &:nth-child(2) {
      border: none;
    }
  }
}

.blue {
  color: var(--text-link, #0A49A5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 25.2px */
}