.container-finance {
  padding: 0 15px;
}

.tab-up {
  padding-top: 14px;
}

.wrapper-card {
  margin: 16px 0 0;
}

.action-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.action-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.link-color {
  color: #0A49A5;
  cursor: pointer;
}

.commissions {
  &__wrapper {
    padding: 15px 15px 0 15px;

    &:last-child {
      padding: 15px 15px 15px;
    }

    &:first-child {
      padding: 15px 15px 0;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #070708;
  }

  &__box-inputs {
    display: flex;
    justify-content: space-between;
  }

  &__name-inner {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__box-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--bottom {
      margin-bottom: 24px;
    }

    &--line {
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 24px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  &__inputs-inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__percent {
    width: 230px;
    height: 40px;
    padding: 8px 12px 7px;
    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    border-radius: 12px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #070708;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 436px) {
      flex-wrap: wrap;
    }
  }

  &__text-gray {
    position: relative;
    top: 5px;
    color: #74747B;
    text-align: right;

    /* Regular/P4 */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
  }
}

.commissions__wrapper:last-child .commissions__box-inner--line {
  border-bottom: none;
  padding-bottom: 0;
}

.payment-methods {
  padding: 15px 15px 15px;

  &__title {
    margin-bottom: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #070708;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #D9D9D9;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }
  }

  &__box-left {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__box-right {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__percent-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #070708;
  }

  &__percent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px 7px;
    gap: 8px;

    width: 121px;
    height: 40px;

    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
  }

  &__width {
    width: 85px;
  }

  &__percent-big {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px 7px;
    gap: 8px;

    width: 280px;
    height: 40px;

    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    border-radius: 12px;

    @media screen and (max-width: 506px) {
      width: 100%;
    }
  }
}

.platform-balances {
  &__wrapper {
    padding: 15px;
  }

  &__filter-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
  }

  &__tex {
    color: #070708;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }

  &__body-wrapper {
     display: flex;
     justify-content: space-between;
     gap: 8px;
  }

  &__box-left {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    gap: 8px;
    background: rgba(177, 177, 181, 0.1);
    border-radius: 12px;
    width: 100%;
    max-width: 348px;
  }

  &__box-right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    gap: 8px;
    background: rgba(177, 177, 181, 0.1);
    border-radius: 12px;
    width: 100%;
  }

  &__box-left-header {
    display: flex;
    justify-content: space-between;
  }

  &__left-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #070708;
  }

  &__left-link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #0A49A5;
  }

  &__left-card {
    display: flex;
    padding: 16px;
    gap: 16px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    border-radius: 12px;

    svg {
      width: 46px;
    }
  }

  &__box-left-body {
    display: flex;
    gap: 16px;
  }

  &__graph-wrap {
    border-radius: 12px;
    background: #F7F7F8;
    margin-top: 16px;
    padding: 24px;
  }

  &__graph-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__graph-title {
    color:#070708;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 25.2px */
  }

  &__graph-btn-wrap {
    display: flex;
    gap: 8px;
  }

  &__graph-filters {
    display: flex;
    gap: 16px;
  }

  &__graph-btn {
    min-width: 59px;
    padding: 8px 12px;
    color: #929298;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;

    &:hover {
      border-radius: 8px;
      background: #0A49A5;
      color: #fff;
    }
  }

  &__graph-btn-active {
    background: #0A49A5;
    border-radius: 8px;
    color: #fff;
  }
}

.statistics-green {
  color: #27AE60;
}

.statistics-red {
  color: #EF4062;
}

.statistics-yellow {
  color: #F2994A;
}

.color-grey {
  color: #74747B;
}

.margin-top-card {
  margin-top: 24px;
  padding-left: 0;
  padding-right: 0;
}

.top {
  margin-top: 24px;
}

.grid-one-colum {
  @media screen and (max-width: 460px) {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 10px;
  }
}

#chart {
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 16px;
}

.card-crypto {
  display: flex;
  gap: 8px;
  margin-top: 16px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    border-radius: 12px;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    gap: 16px;
    width: 100%;
    height: 88px;
    background: #F7F7F8;
    border-radius: 15px;
  }

  &__footer {
    display: flex;
    gap: 8px;
    align-items: center;

    &:first-child {
      margin-left: 13px;
    }
  }

  &__amount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #0A49A5;
  }

  &__gray {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #74747B;
  }

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.gray-background {
  background:  #F6F6F6;
  padding: 1px 16px 16px;
}
