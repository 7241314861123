.button {
    min-width: 105px;
    height: 54px;
    align-items: center;
    gap: 16px;
    background: var(--main-color);
    border: none;
    border-radius: 18px;    
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    transition: 0.2s;  
    svg {
        min-width: 15px;
    }

    &:hover {        
        background: var(--button-hover-color);
    }
    &:disabled {
        color: #939395;
        background: #CFCFD2;
        color: #929298;
        opacity: 1;
    }


    &--type2 {
        color: var(--accent-color);
        border: 1px solid var(--border-color);
        background: none;      

        &:hover {
            color: var(--accent-color);
            border-color: var(--accent-color);
            background: none;
        }
    }
    &--type3 {
        background: none;
        border: 1px solid var(--border-color);
        color: var(--main-color);
        .btn-icon svg path {
            stroke: var(--main-color);
        }
        &:hover {
            background: none;
        }
    }
    &--type4 {
        background: none;
        border: 1px solid var(--border-color);
        color: var(--text-color4);
        .btn-icon .fill path {
            fill: var(--text-color4);
            stroke: none;
        }
        &:hover {
            background: none;
        }
    }
    &--type5 {
      gap: 8px;
      padding: 5px;
      background: none;
      border: 1px solid var(--border-color);
      color: var(--main-color);
      font-weight: 500;
      font-size: 11px;
      line-height: 180%;
      border-radius: 8px;
      &:hover {
            background: none;
      }
    }
    &--td-action {
        flex: 0 0 128px;
        max-width: 128px;
        height: 30px;
        gap: 8px;
        border-radius: 8px;
        padding: 0 11px;
        font-size: 11px;
    }
    
    &--medium-width {
        width: 150px;
    }   
    &--fixed-width {
        width: 228px;
    }
    &--small-width {
      width: 107px;
    }
    &--small-height {
      height: 30px;
    }

    &--header-download {
      width: 88px;
      height: 40px;
      margin-left: auto;
      border: 1px solid #CFCFD2;
      border-radius: 12px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #0A49A5;
      background: transparent;
      &:hover {
        background: none;
      }
    }
}

.input {
    margin-bottom: 16px;
    &__name {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
    }
    
}

.input-item {
    height: 45px;
    border-color: var(--border-color);
    border-radius: 12px;
    padding: 0 12px;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 160%;
    &::placeholder {        
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: var(--text-color2);
    }
    &:focus {
        border-color: var(--main-color);
    }
    &--left-icon {
        padding-left: 38px;
    }
    &--pr50 {
        padding-right: 50px;
    } 
    &--type-td {
        height: 30px;
        font-size: 14px !important;

        &::placeholder {
            font-size: inherit;
        }
    }   
    &--error {
      border-color: #EF4062;
    } 
    &--user-data {
        max-width: 100%;
        height: 40px;
        width: 280px;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
}


.input-icon {
    width: 18px;
    left: 12px;
    &--right {
        right: 12px;
        left: unset;
    }
    path {
        stroke: var(--text-color2);
    }
}



.pagination-block {
    margin-top: 16px;
}

.pagination {
    margin: 0;
    gap: 8px;
    > li {
        padding: 0;
        & + li {
            margin-left: 0;
        }
        > a, > button {
            min-width: 32px;
            width: 32px;
            height: 32px;
            color: #173B58;
            border-color: #E6E8EC;

            &.active {
                // border-color: #3B97D3;
                color: #173B58;
            }
            &:not(.disabled){
                &:hover, &.active {
                    color: #173B58;
                    background: #0A49A5;
                    color: #fff;
                    // background: none;
                    svg path{  
                      stroke: #fff;
                    }
                }
            }
            &.arrow {
                cursor: pointer;
                svg {
                    path {
                        stroke: #173B58;
                    }
                }
                &.disabled {
                    >a,>button {
                        border-color: #E6E8EC;
                        pointer-events: none;
                    }
                    svg {
                        opacity: 0.3 !important;
                    }
                }
            }
            &.disabled {
                cursor: default;
                .stroke path {
                    stroke: #777E90;
                }
            }
        }
    }
}



.radio {
    margin-bottom: 12px;
    &__label {
        gap: 12px;
    }
    &__item {
        min-width: 24px;
        width: 24px;
        height: 24px;
        border: 1px solid var(--border-main-color);
        border-radius: 100px;
    }

    &__text {
        margin-left: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        font-size: 12px;
        color: var(--text-color-main);
    }

    input:checked~.radio__item {
        background: none;
        border-color: var(--accent-color);
        &::after {
            width: 12px;
            height: 12px;
            background: var(--accent-color);
        }
    }
}

.tooltip-wrap {
    display: flex;
    align-items: center;
    gap: 4px;
}

.tooltip {
    padding: 16px 14px;
    background: #FFFFFF;
    border: 1px solid #E6E8EC;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    
    &::after {
        display: none;
    }
    &--width-500 {
        width: 500px !important;
    }
    &__text {
        color: #173B58;
    }
}


.table-block {
    margin-top: 0;
    background: #fff;
    border: none;
    border-radius:10px;
    // height: calc(100% - 185px);
}

.table {
    border-radius: 10px;
    border: none;
    line-height: 1.8;
    font-size: 14px;
    @media screen and (max-width: 1280px) {
        font-size: 13px;
        .link {
            font-size: 13px; 
        }
    }
}

.td-name {
    display: flex;
    align-items: center;
    gap: 8px;
}

.td {
    //padding: 12px 5px;
    line-height: 1.4;
    padding: 3px 5px;
    @media screen and (max-width: 1199px) {
        padding: 12px 15px;
    }
    &:first-child {
        padding-left: 14px;
        @media screen and (max-width: 1199px) {
            padding-left: 15px;
        }
    }
    &:last-child {
        padding-right: 14px;
        @media screen and (max-width: 1199px) {
            padding-left: 15px;
        }
    }
    &--right {
        text-align: right;
        .table-buttons, .td-name {
            justify-content: flex-end;
            @media screen and (max-width: 1199px) {
                justify-content: flex-start;
            }
        }
        @media screen and (max-width: 1199px) {
           text-align: left;
        }
    }
    &--center {
        text-align: center;
        .table-buttons, .td-name {
            justify-content: center;
            @media screen and (max-width: 1199px) {
                justify-content: flex-start;
            }
        }
        @media screen and (max-width: 1199px) {
            text-align: left;
        }
    }
}

.tr {
    min-height: 53px;
}

.table-body {
    .tr {
        border-bottom: 1px solid var(--border-color);
    }
}

.popup-window {
    background: rgba(177, 177, 181, 0.72);
    backdrop-filter: blur(5px);
    &--clear {
      background: none;
      backdrop-filter: none;
    }
    &__inside {
      &--column {
        flex-direction: column;
        gap: 24px;
      }
    }
}


.popup {
    width: 406px;
    box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 36px 44px;
    overflow: hidden;
    @media screen and (max-width: 720px) {
        padding: 24px 16px;
    }  
    &--big {
      width: 616px;
    }
    &--bigger {
      width: 470px;
      padding: 24px;
      @media screen and (max-width: 720px) {
        padding: 16px;
      } 
    }
    &--padding-type2 {
        padding: 24px 0 0 0;
        @media screen and (max-width: 720px) {
            padding: 16px 0 0 0;
        } 
    }
    &--smaller {
      width: 325px;
      padding: 24px;
      @media screen and (max-width: 720px) {
        padding: 16px;
      } 
    }
    &--pr8 {
      padding-right: 8px;
    }
    &--padding-0 {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    &__close {
      display: flex;
      width: 24px;
      top: 26px;
      right: 20px;
      &--40px {
        top: 40px;
      }
      @media screen and (max-width: 720px) {
        top: 19px;
        right: 16px;
      } 
    }
}

.popup-header {
  &--mb16 {
    margin-bottom: 16px;
  }
  &--add-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.popup-header__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  &--left {
    text-align-last: left;
  }
}

.popup-text {
  line-height: 180%;
    &--fs16 {
        font-size: 16px;
    }
    p:not(:last-child) {
      margin-bottom: 8px;
    }
    &--mt16 {
      margin-top: 16px;
    }
    &--add-padding {
      padding-left: 24px;
      padding-right: 24px;
    }
    &--indent-8{
        p:not(:last-child) {
            margin-bottom: 8px !important;
        }
    }
}

.avatar {
  &--square {
    border-radius: 5px;
    width: 32px;
    height: 32px;
    img {
      border-radius: 5px;
    }
  }

  &--circle {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}

.select {
  &--type2 {
    width: 195px;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
    .select__current-arrow {
      flex: 0 0 20px;
      max-width: 20px;
    }
    .select__current {
      font-weight: 400;
      font-size: 14px;
      color: var(--text-color-main);
    }
    .select__drop {
      padding: 0;
      border: 1px solid #CFCFD2;
      box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
      border-radius: 14px;
    }
    .select__drop ul>li {
      &:first-child {
        border-radius: 14px 14px 0 0;
        overflow: hidden;
      }
      &:last-child {
        border-radius: 0 0 14px 14px;
        overflow: hidden;
      }
    }
    .select__drop ul>li>a:hover, 
    .select__drop ul>li>button:hover {
      background: #F7F7F7;
      color: #0A49A5;
      font-weight: 500;
      
    }
  }
}



