.border-none #chart {
  border: none
}

.main-content-color-w {
  border-radius: 10px;
  background: #FFF;
  padding: 15px;
  margin-top: 15px;
}

.card-balance-text {
  color: var(--text-primary-light, #070708);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}

.main-content .card-balance {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-top: 16px;

  &__name {
    color: #74747B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
  }

  &__subname {
    color: var(--text-link, #0A49A5);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
  }

  @media screen and (max-width: 1462px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 460px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
