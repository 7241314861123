.logo-history {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 1142px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 642px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 452px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card-logo {
  display: flex;
  width: 194px;
  height: 194px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  &__wrap {
    border-radius: 12px;
  }

  &__remove {
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 91px;
    background: rgba(7, 7, 8, 0.50);cursor: pointer;
  }

  @media (max-width: 642px) {
    max-width: 194px;
    max-height: 194px;
    width: 100%;
    height: 100%;
  }
}
