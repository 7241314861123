.background {
  margin-top: 20px;
  padding: 15px;

  // background: #FFFFFF;

  &__wrap {
    position: relative;
    background: #FFFFFF;
    margin-top: 15px;
    padding: 15px;
    height: fit-content;
    width: 100%;
  }

  &__wrapper {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D9D9D9;
  }

  &__grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 16px;
    gap: 16px;

    &:last-child {
      margin-bottom: 0px;
    }

    @media screen and (max-width: 852px) {
      grid-template-columns: 1fr;
    }
  }

  &__select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 402px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    white-space: nowrap;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__textarea {
    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
    padding: 8px 12px 7px;
    width: 100%;
    resize: none;
    height: 174px;
    margin-top: 8px;
  }

  &__wrapper-drop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 25px;

    @media screen and (max-width: 852px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  &__title-drop-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title-drop {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #070708;
  }

  &__subtitle-drop {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #B1B1B5;
  }

  &__inner-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed #0A49A5;
    border-radius: 12px;
    // width: 100%;
    padding: 33px 15px;
    // margin: 15px;
  }

  &__drop-dtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
    max-width: 102px;
    max-height: 44px;
    overflow: hidden;
    padding: 10px 16px;
  }

  &__btn-name {
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 3;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: #0A49A5;
    pointer-events: none;
  }

  &__text-gray {
    max-width: 187px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #74747B;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 4px;

    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #070708;
  }

  &__img-wrap {
    position: relative;
    height: 64px;
    width: 55px;
    overflow: hidden;
  }

  &__img-svg {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

.opacity {
  margin-top: -10px;
  margin-left: -16px;
  opacity: 0;
  z-index: 0;
}

.upper-first-word {
  text-transform: capitalize;
}

.oval-wrap {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}