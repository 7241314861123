.admin-rule {
  border-bottom: 1px solid #CFCFD2;
  margin: 30px 15px;
  padding-bottom: 15px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &:first-child {
    margin: 0 15px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 75px;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }

    @media screen and (max-width: 610px) {
      grid-template-columns: 1fr;
    }
  }

  &__rule {
    margin-top: 16px;
  }

  &__rule-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #070708;
  }

  &__rule-body {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #74747B;
    margin-top: 3px;
  }
}

.tr-admin {
  padding-bottom: 17px;
  margin-bottom: 17px;
}

.tr-admin-body {
  &:last-child {
    margin-bottom: 0;
  }
}

.tr-admin .td .td-name p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #070708;
}

.tr-admin .td {
  padding: 0 10px 0 0;

  @media screen and (max-width: 1199px) {
    padding: 0 10px 10px 0;
  }
}

.table-header .tr-admin {
  border-top: none;
  border-bottom: 1px solid #CFCFD2;
  min-height: 100%;
}

.filter-flex-between {
  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 476px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}
