.bots-statistics {
  padding-top: 24px;

  &__card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.6fr;
    padding: 30px;
    border-radius: 15px;
    background: var(--BG-Undead-Light);
  }
}
