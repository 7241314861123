.table-tabs-custom {
  padding: 0 16px;
}

.input-custom {
  margin-bottom: 0;
}

.input-search {
  max-width: 240px;
  justify-self: flex-end;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    // min-width: 100%;
  }
}

.filter-custom {
  &__inputs-block {
    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 1024px) {
        grid-template-columns: 1.5fr 0.5fr;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 8px;
      }
    }
  }

  &__inputs-inner {
    display: flex;
    border-top: 1px solid #CFCFD2;
    padding-top: 25px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 8px;
      margin-right: 0;
    }
  }
}

.td-break {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.td {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

// .td-name {
//   font-size: calc(0.4rem + 0.5vw);
// }

.td--righ-custom {
  display: flex;
  justify-content: flex-end;
}

.input-search-custom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
}

.input-search-width380 {
  max-width: 380px;
  
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.input-wrapper-custom {
  width: 100%;
  max-width: 380px;
  margin: 10px 10px 0 0;

  @media screen and (max-width: 669px) {
    max-width: 96%;
    margin: 10px 10px 0 10px;
  }
}

.input-wrapper-custom100 {
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.table-tabs-custom {
  padding-top: 0;
}

.table-padding {
  padding: 15px;
}

.button-left-margin {
  gap: 8px;
}

.tabs-top-margin {
  margin-top: -35px;

  @media screen and (max-width: 1358px) {
    margin-top: 0;
  }
}

.header-title .select-code-generation {
  position: relative;
  bottom: 15px;
  margin-bottom: 28px;
}

.wrap-export-btn {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
