.qr-code {
  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #070708;
    text-align: center;
    margin-top: 16px;
  }

  &__wrapper {
    width: 120px;
    margin: 0 auto;
    padding: 16px 0;
  }

  &__info {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 180%;
    display: flex;
    align-items: center;
    color: #B1B1B5;
  }
}