.pricing {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 15px;

  &__card {
    padding: 30px;

    background: var(--BG-Undead-Light);
    border-radius: 15px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    color: var(--color-text-dark);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }

  &__box-input-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 28px;
  }

  &__box-input-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-name {
    color: var(--color-text-dark);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
  }

  &__input {
    max-width: 600px;
    width: 100%;
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid var(--line-line-light);
    background: var(--color-text-white);
  }
}