.track-details {
  padding: 15px;
  background: #FFF;
  margin-top: 40px;

  &__header {

  }

  &__block-img {
    display: flex;
    justify-content: center;
  }

  
  &__body {

  }

  &__title {
    color: var(--color-text-dark, #070708);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    
    /* Title 20px Medium */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 0.15px;
    margin: 24px 0 8px;
  }

  &__subtitle {
    color: #929298;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    margin-bottom: 24px;
    text-align: center;
  }

  &__desc {
    max-width: 688px;
    color: var(--color-text-dark, #070708);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0 auto;
  }

  &__title-h3 {
    color: var(--text-primary-light, #070708);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }

  &__list--padding {
    padding-top: 16px;
  }

  &__list {
    margin-bottom: 16px;
    border-bottom: 1px solid #D9D9D9;
  }

  &__item {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin-bottom: 16px;
  }

  
  &__footer {

  }

  &__file-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid var(--color-divider-grey, #CFCFD2);
    margin-top: 8px;
  }

  &__file-info {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
