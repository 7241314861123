.total-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 24px;

  &__box {
    display: grid;
    padding: 16px 24px;
    border-radius: 12px;
    border: 1px solid #EEEEEF;
    background: #FFF;
  }

  &__wrap-title {
    margin-bottom: 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    gap: 8px;
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
  }

  &__subtitle {
    color: #0A49A5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
  }

  @media screen and (max-width: 1068px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.blue-big {
  color: #0A49A5;
  /* Regular/P4 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 25.2px */
}
