.table-block-system {
  margin-top: 16px;
}

.system {
  padding: 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wrap-btns {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  &__btns {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    position: relative;
    padding: 15px 8px 15px 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top: 1px solid var(--color-button-dark-grey, #CFCFD2);
    border-bottom: 1px solid var(--color-button-dark-grey, #CFCFD2);
    border-left: 1px solid var(--color-button-dark-grey, #CFCFD2);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
      display: block;
      width: 1px;
      height: 16px;
      background: var(--color-divider-grey, #CFCFD2);
    }
  }
  
  &__row {
    padding: 15px 16px 15px 8px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid var(--color-button-dark-grey, #CFCFD2);
    border-bottom: 1px solid var(--color-button-dark-grey, #CFCFD2);
    border-right: 1px solid var(--color-button-dark-grey, #CFCFD2);
  }

  &__create-new {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--color-button-blue, #0A49A5);
    padding: 13px 24px;

    color: var(--color-text-white, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }

  &__body-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    margin-top: 16px;
  }

  &__body-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-top: 16px;
  }

  &__channels-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    border-radius: 12px;
    border: 1px solid var(--color-divider-light-grey, #F2F2F2);
  }

  &__channels-wrap-start {
    align-items: flex-start;
  }

  &__points {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__desc {
    margin-top: 32px;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 8px;
  }

  &__title {
    color: #070708;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &__subtitle {
    color: var(--color-grey, #929298);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  &__popup-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(177, 177, 181, 0.72);
    backdrop-filter: blur(4px);   
  }

  &__popup {
    position: relative;
    max-width: 420px;
    width: 100%;
    padding: 40px;
    border-radius: 10px;
    background: var(--bg-primary, #FFF);
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.08);
    text-align: center;

    img {
      margin: 0 auto;
    }
  }

  &__popup-title {
    color: var(--text-primary-light, #070708);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-bottom: 24px;
  }

  &__form-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  &__popup-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

.text-gray-2 {
  margin: 8px 0 24px;
  color: var(--color-text-gray, #929298);

  /* Regular 14px */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-align: left;
}
