.ai-bot-status {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}

.ai-bot-status {
  color: #070708;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.ai-bot-body {
  padding: 0 15px;
}

.ai-bot-status-active {
  color: #27AE60;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 25.2px */
}

.ai-bot-title {
  color: #070708;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
