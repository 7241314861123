.input-gray {
  position: relative;
  width: 100%;
  // border-bottom: 1px solid #CFCFD2;
  color: var(--color-text-dark, #070708);

  /* Regular 16px */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  
  &:last-child {
    border-bottom: none;
  }
}

.input-gray-wrapper {
  background: var(--color-bg-grey);
  border-radius: 14px;
}

.form-group .border-none {
  border: none;
  background: var(--color-bg-grey);
  border-radius: 14px;
}

.form-group .border-none-marg {
  margin-top: 12px;
}

.input-gray-item {
  outline: none;
  max-width: 100%;
  border: none;
  width: 100%;
  background: transparent;
  height: 48px;
  padding: 0 16px;
}
