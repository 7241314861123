.input-search-padding {
  padding: 15px 15px 24px;
}

.username-filter {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 15px 0 24px;

  &__search-wrap {
    display: flex;
    width: 100%;
    gap: 8px;
  }

  &__wrap-input {
    width: 100%;
  }

  &__select-wrap {
    width: 100%;
  }

  &__inner {
    width: max-content;
  }
}

.request-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.request-btn {
  display: flex;
  align-items: center;
  gap: 8px;

  &__btn-reject {
    padding: 16px 24px;
    color: var(--input-error);
    background: #FDE7EB;

    font-size: 16px;
    font-family: Roboto;
    line-height: 24px;
    border-radius: 10px;
  }

  &__btn-confirm {
    font-size: 16px;
    font-family: Roboto;
    line-height: 24px;
    border-radius: 10px;
    background: var(--color-button-blue);
    padding: 16px 24px;
    color: var(--ui-popup-bg);
  }
}

.request {
  padding: 15px;

  &__title {
    color: var(--color-text-dark);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 180%;
    margin: 16px 0;

    &--margin-bottom {
      margin: 0 0 16px;
    }
  }

  &__subtitle {
    color: var(--color-text-dark);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 180%;
  }

  &__text {
    color: var(--color-text-dark);
    font-size: 14px;
    font-family: Roboto;
    line-height: 180%;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }

  &__list-ul {
    display: grid;
    gap: 16px;
  }

  &__file-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    margin-bottom: 16px;

    border-radius: 12px;
    border: 1px solid #CFCFD2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__file-inner {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

.line-bottom {
  padding-bottom: 16px;
  border-bottom: 1px solid #D9D9D9;
}

.gap-list {
  display: grid;
  gap: 16px;
}

.line-right {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #D9D9D9;
    top: 0;
    right: 20px;

    @media screen and (max-width: 1024px) {
      width: 0;
    }
  }
}

.margin16 {
  margin-top: 16px;
}
