.limits {
  padding: 32px 15px;

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    
    &:first-child {
      margin-bottom: 29px;
    }

    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }

  &__name {
    color: #74747B;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
  }

  &__input {
    display: flex;
    padding: 8px 12px 7px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }
}
