.sidebar-phone {
  display: block;
}

.main-section .minimized-custom {
  width: 72px;
}

.input-wrapper .input-item--code-select {
  padding-left: 16px;
}

.PhoneInputInput {
  border: none;
  outline: none;
}

div .PhoneInputCountrySelectArrow {
  margin-left: 14px;
}

.input-wrapper .select__current-arrow {
  position: absolute;
  top: 27px;
  left: 46px;
}

.uploaded-photo .upload-action {
  background-color: rgb(187, 156, 156, 0.3);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.admin-section .container header {
  margin-top: -81px;
  border: none;
  position: relative;
  z-index: 3;
  background-color: white;
}

.container .popup-window {
  overflow: hidden;
  height: 100%;
  // height: 100vh;
}

.photo-slider .photo-slide {
  max-width: 100%;
}

.container .popup-window {
  &__inside {
    max-width: 1334px;
    margin: 0 auto;
  }
}

.photo-slide img {
  max-height: 545px;
}

.photo-slider-box {
  position: relative;
}

.swiper .slider-arrow--next {
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
  z-index: 5;
  max-height: 60px;
  max-width: 60px;
  width: 100%;
  height: 100%;
}

.swiper .slider-arrow--prev {
  position: absolute;
  left: 0;
  top: calc(50% - 30px);
  z-index: 5;
  max-height: 60px;
  max-width: 60px;
  width: 100%;
  height: 100%;
}

.td .status-custom-0 {
  color: #E41472;
}

.td .status-custom-1 {
  color: #25A353;
}

.td .status-custom-2 {
  color: #C51918;
}

.swiper .swiper-wrapper {
  display: flex;
  align-items: center;
}

.button-custom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 110px;
  grid-gap: 8px;
  gap: 8px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: #F3F3F3;

  &:not([disabled]):hover{
    background: #d81159;
    color: white;
  }
}

.button-custom--color {
  background: #d81159;
  color: white;
}

.input-wrapper .textarea-default {
  min-height: 120px;
}

.input-wrapper .input-item-center {
  display: flex;
  align-items: center;
}

.realty-actions--opacity {
  opacity: 0;
}

.table-title--flex {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.select-z-index {
  z-index: unset;
}

.block-inline-custom {
  display: inline-block;
  position: relative;
  z-index: 3;
}

.block-position-custom-first {
  position: absolute;
  left: -171px;
  top: 50px;

  @media screen and (min-width: 685px) {
    left: -170px;
  }

  @media screen and (min-width: 768px) {
    left: -576px;
  } 
}

.block-position-custom {
  position: absolute;
  left: -295px;

  top: 50px;

  @media screen and (min-width: 684px) {
  left: -576px;

  }

  // @media screen and (min-width: 768px) {
  //   left: -576px;
  // } 
}

.wrapper-culendar-custom {
  display: flex;
}

.select-date {
  color: #b6b6b6;
}

.admin__content .table--orders-management .tr {
  grid-template-columns: 0.91fr 1.95fr 1.55fr 1.51fr 1.4fr 0.9fr 0.65fr;
}

.input-lenght {
  font-size: 12px;
}

.input-custom-pading {
  padding-right: 60px;
}

.input-wrapper-year-custom .block-inline-year {
  width: 100%;
}

.input-wrapper-year-custom .block-inline-year .block-position-calendar-custom {
  width: 100%;
}

.input-wrapper-year-custom .block-inline-year .block-position-calendar-custom div .react-datepicker {
  width: 100%;
  max-width: 184px;
}

.input-wrapper-year-custom
.block-inline-year
.block-position-calendar-custom div
.react-datepicker
.react-datepicker__navigation--previous  {
  margin-left: 0;
}

.input-wrapper-year-custom
.block-inline-year
.block-position-calendar-custom div
.react-datepicker
.react-datepicker__navigation--next  {
  margin-right: 0;
}