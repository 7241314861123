.term {
  padding: 15px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__item {
    display: grid;
    grid-template-columns: 0.5fr 1fr;

    span {
      color: var(--text-primary-light, #070708);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  &__btn-save {
    color: var(--text-link, #0A49A5);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    border-radius: 12px;
    border: 1px solid var(--line-line-light, #CFCFD2);
    background: var(--bg-accent-1-light, #FFF);
    padding: 10px 0;
    width: 149px;
  }

  &__btn-public {
    color: var(--bg-primary, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    border-radius: 12px;
    background: var(--button-primary, #0A49A5);
    padding: 10px 0;
    width: 149px;
  }
}