@mixin roboto-15px-normal($color) {
  color: $color;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

@mixin roboto-14px-normal($color) {
  color: $color;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

@mixin roboto($color, $size, $weight, $lh) {
  color: $color;
  font-family: Roboto;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $lh;
}

@mixin btn {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  gap: 8px;
}
