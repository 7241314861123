.photo-slider-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0 80px;
  width: 100%;
  margin-top: 40px;

  @media screen and (max-width: 1280px) {
    margin-top: 100px;
    gap: 0 40px;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% + 20px);

    .slider-arrow {
      display: none;
    }
  }
}

.slider-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  flex: 0 0 60px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  @media screen and (max-width: 1280px) {
    flex: 0 0 40px;
    height: 40px;

    svg {
      width: 12px;
    }
  }

  svg {
    width: 15px;
  }

  &:disabled {
    background: rgba(37, 37, 46, 0.3);

    svg {
      opacity: 0.2;
    }
  }

  &--prev {
    svg {
      margin-left: -4px;
      transform: rotate(180deg);
    }
  }

  &--next {
    svg {
      margin-right: -4px;
    }
  }
}

.photo-slide {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    border-radius: 15px;

    @media screen and (max-width: 767px) {
      border-radius: 0;
    }
  }
}

.admin-section .container header {
  margin-top: -81px;
  border: none;
  position: relative;
  z-index: 3;
  background-color: white;
}

.container .popup-window {
  overflow: hidden;
  height: 100vh;
}

.photo-slider .photo-slide {
  max-width: 100%;
}

.container .popup-window {
  &__inside {
    max-width: 1334px;
    margin: 0 auto;
  }
}

.slider-header {

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
  }

}

.popup-window-dark {
  background: rgba(7, 7, 8, 0.72);
  backdrop-filter: blur(4px);
}

.photo-slider {
  width: 100%;
}

.mySwiper {
  position: relative;
  z-index: 1000000;
  height: 64px;
  box-sizing: border-box;
  padding: 10px 0;
  width: 250px;
  margin-top: 10px;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  display: none;
}

.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
}