.apk-file {
  background: #FFFFFF;

  &__body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__inputs-wrap {
    display: flex;
    gap: 8px;
  }

  &__list {
    margin-top: 25px;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 0.8fr 0.6fr 1.2fr;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #D9D9D9;
    padding: 0 15px;
  }

  &__item-header {
    border-top: 1px solid transparent;
    padding: 11px 15px;
  }

  &__content {
    padding: 0 15px;
  }

  &__btn-wrap {
    display: flex;
    gap: 5px;
  }
}

.drop-custom {
  height: 200px;
}

.drop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 1px dashed #0A49A5;
  padding: 24px;
  margin-bottom: 24px;

  &__type-title {
    color:#070708;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
    margin: 4px 0;
  }

  &__types {
    color: #74747B;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 21.6px */
  }

  &__btn {
    padding: 10px 16px;
    margin-top: 16px;
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;

    color: #0A49A5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 25.2px */
  }
}

.or-another {
  position: relative;
  color: #74747B;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  display: flex;
  justify-content: center;
  margin: 16px 0;

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background: #D9D9D9;
    width: 146px;
    left: 0;
    top: calc(50% - 2px);
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background: #D9D9D9;
    width: 146px;
    right: 0;
    top: calc(50% - 2px);
  }
}

.poses-list {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 24px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    border-radius: 15px;
    border: 1px solid #EEEEEF;
    padding: 16px;
  }
}

.action-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button-blue-width {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 48px;
  margin: 0 auto;
}

.form-submit-flex {
  display: flex;
  justify-content: space-between;
  gap: 150px;

  &__share {
    width: 100%;
    padding: 16px 0;
    border-radius: 14px;
    background: var(--color-button-blue, #0A49A5);
    color: var(--color-text-white, #FFF);
  }

  &__download {
    width: 100%;
    padding: 16px 0;
    border-radius: 14px;
    border: 1px solid var(--color-button-dark-grey, #CFCFD2);
    color: var(--color-text-dark, #070708);
  }

  &__btn-text {
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 115.385% */
  }
}

.registration {
  &__file-wrap {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid var(--color-divider-grey, #CFCFD2);
    margin: 8px 0 24px;
  }

  &__file-inner {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  &__file-name {
    display: flex;
    align-items: center;
    color: #070708;
    /* Regular/P3 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
  }
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #007aff !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #007aff !important;
}
