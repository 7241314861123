.discount {
  padding: 15px;

  &__first-wrap {
    max-width: 500px;
    width: 100%;
  }

  &__title {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-bottom: 6px;
  }

  &__title2 {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  &__desc {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    padding: 16px 0
  }

  &__input-wrapper {
    margin: 16px 0 24px;
  }

  &__padding {
    padding-top: 18px;
  }
}