.template {

}

.template-btn {
  width: max-content;
}

.warning {
  color: #e23434;
  text-align: right;
  font-size: 13px;
  margin-top: -15px;
}
