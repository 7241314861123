.report-details {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
  }

  &__header-left-box {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  &__header-text {
    color: #0A49A5;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__header-right-box {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__reject {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__header-text-green {
    color: #27AE60;
    text-align: center;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  &__header-text-red {
    color: #EF4062;
    text-align: center;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  &__title {
    color: #070708;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    padding: 16px 0;
  }

  &__text {
    color: #070708;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    margin-bottom: 16px;
  }

  &__report-info {
    max-width: 591px;
    width: 100%;
  }

  &__report-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__line-bottom {
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }

  &__message-wrap {
    padding: 15px 15px 0 15px;
    gap: 20px;
  }

  &__message-inner {
    background: url('../../../../assets/images/background.png'), lightgray 50% / cover no-repeat;
    border-radius: 12px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    overflow-x: auto;
    max-height: 240px;

    scrollbar-width: thin;

    &::-webkit-scrollbar {
      border-left: 1px solid #FFF;
      padding-left: 5px;
      width: 20px;
    }
  
    // &::-webkit-scrollbar-track {
    //   border-left: 9px solid #d8d8d8;
    //   border-right: 9px solid #d8d8d8;
    // }
  
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 8px;
      width: 4px;
      background-clip: content-box;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  &__message-wrap {
    display: flex;
  }

  &__avatar {
    width: 35px;
    height: 35px;
    border-radius: 15px;
    border: 1px solid #FFF;
    align-self: flex-end;
    img {
      border-radius: 15px;
    }
  }

  &__sms-text-wrap {
    position: relative;
    display: flex;
    border-radius: 12px;
    background: #FFF;
    max-width: 362px;
    padding: 8px 16px 8px 8px;
    gap: 16px;
  }

  &__autor-name {
    color: #EF4062;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  &__autor-text {
    color: #070708;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    margin-top: 16px;
  }

  &__text-time {
    color: #929298;
    text-align: right;
    font-size: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-top: 16px;
  }

  &__corner {
    position: absolute;
    left: -10px;
    bottom: 0;
  }

  &__sms-left-content {
    min-width: 260px;
  }

  &__sms-right-content {
    margin-top: 8px;
  }
}