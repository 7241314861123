.title-create-bot {
  background-color: #F6F6F6;
  border-radius: 15px;
  padding: 16px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;

  &::first-letter {
    text-transform: capitalize;
  }

  margin-bottom: 15px;
}

.header-title-flex {
  display: flex;
  gap: 15px;
  align-items: center;
}
