.structure {
  display: grid;
  gap: 16px;

  &__box {
    width: 100%;
    padding: 16px 30px;
    align-self: stretch;
    border-radius: 15px;
    background: #F7F7F8;
    gap: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    cursor: pointer;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: var(--Color-Text-Dark, #070708);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }

  &__line {
    display: flex;
    flex-direction: column;
    gap: 3px;

    span {
      height: 1px;
      width: 15px;
      background: #070708;
    }
  }
}

.accordion {
  width: 100%;
  border-top: 1px solid #D9D9D9;
  padding-top: 16px;
  margin-top: 16px;

  &__box {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr;
    width: 100%;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__box-text {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    width: 100%;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__box-2 {
    grid-template-columns: 1fr 1fr;

    @media (max-width: 642px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__left-content {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__label {
    display: flex;
    flex-direction: column;
    color: var(--Text-Primary_Light, #070708);
    /* Regular/P3 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
  }

  &__input {
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    padding: 12px;
  }
}

.accordion__right-content .background__wrapper-drop {
  width: 100%;
}