.popup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(177, 177, 181, 0.72);
  backdrop-filter: blur(4px);
}

.popup-box {
  position: relative;
  padding: 40px;
  border-radius: 10px;
  background: var(--bg-primary, #FFF);
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.08);
  min-width: 420px;
  margin: 15px 0 10px;
  overflow-x: auto;
  max-height: 700px;

  &__title {
    color: var(--text-primary-light, #070708);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    margin-bottom: 24px;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__btn-close {
    display: flex;
    height: 54px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin: 0 auto;
    border-radius: 18px;
    background: #0A49A5;
    width: 340px;

    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }

  &__btn-border {
    display: flex;
    height: 54px;
    width: 100%;
    margin-top: 12px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 18px;
    border: 1px solid var(--Line-Line_Light, #CFCFD2);
    background: var(--bg-accent-1-light, #FFF);

    color: var(--Text-Link, #0A49A5);

    /* Medium/P3 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
  }

  &__label {
    color: #070708;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 21.6px */
    margin-bottom: 8px;
  }
}

/* Стиль самого скролбару */
::-webkit-scrollbar {
  width: 12px; /* Ширина вертикального скролу */
  height: 12px; /* Висота горизонтального скролу */
}

/* Стиль треку скролбару (фон) */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}

/* Стиль "повзунка" скролбару */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Колір повзунка */
  border-radius: 10px; /* Закруглення кутів */
  border: 2px solid #f1f1f1; /* Відступ повзунка від треку */
}

/* Hover-ефект для повзунка */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
