.admin-info {
  padding: 15px;
  background: #FFFFFF;
  margin-top: 20px;

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
  }

  &__btn-del {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 15px;
    cursor: pointer;
  }

  &__box-first {
    border-bottom: 1px solid #D9D9D9;
  }

  &__list {
    margin-top: 24px;
  }

  &__item {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    margin-bottom: 16px;
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
  }

  &__input {
    padding: 8px 12px 7px;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
    width: 100%;
    max-width: 277px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #070708;
  }
}