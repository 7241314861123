

:root {
    /*--------Main--------*/  
    --BG-Undead-Light: #F6F6F6;

    --ui-main-color: #d81159;

    --ui-button-bg: #d81159;
    --ui-button-bg-hover: #ee0259;

    --ui-color-grey: #A6B0C3;

    --ui-block-bg: #fff;
    --ui-border-color: #c9c9c9;
    --ui-border-light-color: #EEEEEE;

    

    --ui-input-border-color: #b6b6b6;  

    --ui-checkbox-border: #b6b6b6;
    --ui-checkbox-bg: #0A49A5;

    --ui-swith-color: #E1E0E0;

    /*---------Text-------*/

    --ui-text-color: #000;
    --ui-text-color-light:#8F9BBA;
    --ui-text-color-grey: #9EA2A9;
    --ui-error-color: #EB5757;
    --ui-success-color: #27AE60;

    /*---------End_Text-----*/

    --ui-select-border: #b6b6b6;

    --ui-popup-bg: #fff;

    --ui-pagination-bg: #fff;
    --ui-pagination-border: #b6b6b6;
    --ui-pagination-color: #000;
    --color-text-white: #fff;


    --ui-upload-box-color: #53C48A;

    --ui-progress-bg: #D4F0E2;
    --ui-progress-line: #88D39F;
    --ui-progress-label-bg: #1F2937;

    --ui--tooltip-bg: #1F2937;

    --color-divider-grey: #CFCFD2;
    --color-text-dark: #070708;
    --color-button-blue: #0A49A5;
    --color-checkbox-gray: #B1B1B5;
    --line-line-light: #CFCFD2;
    --input-error: #EF4062;
    --background-gray: #F7F7F8;
}


