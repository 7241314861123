.sidebar-right {
  width: 320px;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  padding: 16px 20px;

  &__photo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px 10px;
    background: #F7F7F7;
    border-radius: 12px;
  }

  &__user-info {
    transform: rotate(-90deg);
    position: absolute;
    bottom: -37px;
    left: 0;
    white-space: nowrap;
  }

  img {
    border-radius: 50%;
    width: 80px;
  }
  
  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #070708;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__content-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin: 16px 0 8px;
  }

  &__text-gray {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #929298;

    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #070708;
    white-space: nowrap;
  }

  &__more {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #0A49A5;
    padding-bottom: 16px;
    border-bottom: 1px solid #CFCFD2;
    cursor: pointer;
  }

  // &__notes-wrap {

  // }

  &__button-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 16px 0 24px;
  }

  &__notes {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #070708;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #0A49A5;

      width: 19px;
      height: 19px;
      background: #F0F6FF;
      border-radius: 10px;
      margin-left: 8px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #CFCFD2;
    border-radius: 12px;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      color: #0A49A5;
    }
  }

  &__sms-wrap {
    display: flex;
    gap: 12px;
    // transform: rotate(180deg);
    direction: ltr;
    padding-left: 10px;
    min-height: 131px;
  }

  &__ava {
    width: 44px;
  }

  &__sms-content {
    width: 100%;
  }

  &__wrap-name {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text-pading {
    margin-top: 6px;
  }

  &__overflow {
    overflow-x: auto;
    width: 105%;
    max-height: calc(100vh - 530px);

    // transform: rotate(180deg);
    scrollbar-width: thin;
    direction: rtl;
    margin-left: -15px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    // &::-webkit-scrollbar-track {
    //   background: #B1B1B5;
    //   border-radius: 12px;
    // }

    &::-webkit-scrollbar-thumb {
      background: #B1B1B5;
      border-radius: 30px;
      // border: 3px solid orange;  /* отступ вокруг бегунка */
    }
  }
}

.sidebar-right-heid {
  transition: width 0.4s;
  width: 65px;
}

.suport__header-right-heid {
  position: relative;
}
