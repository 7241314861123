.tabs-buttons-nav {
  display: flex;
  gap: 8px;
  margin-top: 14px;
  >.tabs-buttons-nav-link {
    position: relative;
    min-width: 148px;
    color: #070708;
    text-align: center;
    padding: 0 5px;
    padding: 16px 12px;
    border-radius: 8px 8px 0px 0px;    
    font-size: 16px;    
    &:first-child {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 6px;height: 6px;
        background: #fff;
        display: none;
      }
      &.active {
        &:after {
          display: block;
        }
      }
    }
    &.active {
      background: #fff;
      font-weight: 500;
    }

  }

  @media screen and (max-width: 1400px) {
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 16px;
    //background-color: rgba(10, 73, 165, 0.1);
    //border-radius: 8px;
    //padding: 16px;
    >.tabs-buttons-nav-link {
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      &.active {
        background: var(--main-color);
        color: #fff;
      }
      &:first-child.active:after {
        display: none;
      }
    }
  }
}

.table--channels-loader {
  height: 100%;
  min-height: 100%;
}

.avatar-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  background-color: #f2994a;
  border-radius: 45%;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
  }
}

.upper-text-custom {
  text-transform: capitalize;
}

.vertified-btn-custom {
  margin: 0 auto;
}

.upper-custom {
  text-transform: capitalize;
}

.light-green {
  color: #47e0a0 !important;
}

.select .select__drop--active {
  display: block;
}

.td--right-custom {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  justify-content: flex-end;

  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
    column-gap: 5px;
  }
}

.td--left {
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
    flex-direction: column;
  }
}

.filter-wrapper {
  padding: 15px;
}

.filter-custom {
  &__inputs-block {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    align-items: center;

    .input {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .button--reset-height {
      height: 40px;
    }
  }

  &__calendar {
    display: flex;
    justify-content: space-between;
    max-width: 328px;
    gap: 8px;

    @media screen and (max-width: 632px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__inputs-inner-custom {
    opacity: 0;
    height: 0;
    display: none;
    // overflow: hidden;
    margin-right: 0;
    margin-bottom: -15px;
  }

  &__{
    display: flex;
    justify-content: space-between;
  }

  &__country-wrapper {
    max-width: 380px;
  }
}

.open-custom {
  opacity: 1;
  display: block;
  height: 100%;
}

.select-custom-width {
  width: 100%;
  max-width: 380px;
}

.select__drop-custom-country {
  left: -142px;
}

.gray {
  color: #B1B1B5 !important;
}

.gray-light {
  color: #74747B;
  text-align: center;
  
  /* Regular 14px */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.gray-link {
  color: #74747B !important;
}

.input-wrapper .gray-text {
  color: #B1B1B5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
  margin-top: 4px;
}

.user-data__header .channels-data {
  @media screen and (max-width: 573px) {
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }
}

.margin-top {
  margin-top: 16px;
}

.table-block .input-search-wrapper {
  justify-content: space-between;
}

.table-tabs__item-custom {
  padding-bottom: 0;
}

.table-tabs__item-padding {
  &::before {
    content: '';
    bottom: 0;
  }
}


.table-block-wrapper {
  border-radius: 0;
  margin-top: 16px;
}

.user-data__body .details-exemple {
  display: flex;
  border: none;
  border-radius: 0;
  padding: 16px 0 24px;
  margin-top: 20px;
  border-top: 1px solid #CFCFD2;
  gap: 15px;

  @media screen and (max-width: 1068px) {
    flex-direction: column;
  }
}

.user-data__body .details-exemple-custom {
  border-bottom: 1px solid #CFCFD2;
}

.files-list {
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    background: #F7F7F8;
    padding: 8px;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__img {
    border-radius: 12px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin: 0 16px;

    span {
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  &__block-left {
    display: flex;
    align-items: center;
  }

  &__block-right {
    display: flex;
    align-items: center;
    gap: 8px;

    div { 
      svg {
        min-width: 24px;
        min-height: 24px;
      }
    }
  }

  &__block-right-custom {
    justify-self: flex-end;

    svg {
      cursor: pointer;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #070708;
    margin-bottom: 8px;
  }
}

.media {
  &__wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 16px;
    background: #F7F7F8;
    border-radius: 12px;
  }

  &__file-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 128px;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
  }
}

.media__wrapper .details-user__box-tex-close {
  // width: auto;
  margin: 0 44px;
}

.filter-custom .input-item-margin, .table-block .input-item-margin {
  padding-top: 15px;
  padding-right: 15px;
}

.filter-flex-between {
  display: flex;
  justify-content: space-between;
}