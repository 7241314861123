.ai-bots {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 15px 24px;
  gap: 4px;

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding-right: 20px;
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    border-radius: 15px;
    background: var(--BG-Undead-Light);
  }

  &__blok-text {
    width: 100%;
  }

  &__title {
    color: var(--color-text-dark);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 8px;
  }

  &__type-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
    color: #070708;
    text-transform: capitalize;
  }

  &__list {
    width: 100%;
    display: grid;
    gap: 5px;
    justify-content: space-between;
    grid-template-columns: repeat(5, max-content);
  }

  &__subtitle {
    color: var(--color-text-dark);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__type {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #74747B;
    margin-bottom: 5px;
  }

  &__select-list {
    display: flex;
    width: max-content;
    gap: 8px;
  }

  &__btn {
    display: flex;
    align-items: center;

    gap: 8px;
    padding: 10px 16px;
    width: max-content;
    cursor: pointer;

    border-radius: 12px;
    border: 1px solid var(--color-divider-grey);
    background: var(--color-text-white);
  }

  &__btn-save {
    padding: 10px 16px;
    width: 63px;
    height: 44px;
    text-align: center;
    background: #0A49A5;
    border-radius: 12px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: #FFFFFF;

    align-self: flex-end;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;justify-content: center;
    gap: 15px;
  }

  &__btn-name {
    color: var(--color-button-blue);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 25.2px */
  }

  &__filter-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #CFCFD2;
    padding-top: 30px;
    margin-bottom: 24px;
  }
}

.ai-bots .react-toggle--checked .react-toggle-track {
  background: #0A49A5;
}
