.not-found {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color:  var(--bg-main);
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 45px 0 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 62px;
    text-align: center;
    color: #070708;
  }

  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #40525F;
  }

  &__btn {
    padding: 10px 16px;
    margin-top: 35px;
    width: 141px;
    height: 44px;
    background: #0A49A5;
    border-radius: 12px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #FFFFFF;
  }
}