:root {
  --background-main: #0e0e0e; //main background
  --background-secondary: #171717; //secondary block background color
  --background-third: #CFCFD2;
  --background-fourth: #1e1e1e; // Select drop bacbround and etc
  --main-color: #007aff; // brand color - main btns and accent elements
  --main-color-hover: #007aff;
  --main-color-active: #CFCFD2;
  --text-color-main: #1e1e1e; //main text color
  --text-color-secondary: #8b8b8b;
  --text-color-placeholder: #515151; //color of placeholders
  --text-color-light: #a8a8a8; //light text, light placeholder
  --text-color-white: #fff;
  // --border-color: #343434;
  // --border-color2: #2b2b2b; 
  --border-width: 1px;
  --border-radius: 4px;
  --icon-color: #007aff;  // color of input icons, etc
  --icon-color2: #515151; // secondry color of input icons, etc
  --icon-color-main: #ffffff;
  --btn-and-input-radius: 4px; // border radius of default size btn, input, select and etc
  --block-radius: 15px; //border radius of content-blocks
  --error-color: #ff004d;
  --red-color: #ff004d;
  --red-color-hover: #BF0039;
  --disabled-color: #343434;
  --color-text-gray: #929298;
  --color-button-blue: #0A49A5;
  --red: #EF4062;
  --text-primary-light: #070708;
  --gray-main: #F7F7F7;
  --gray-text: #74747B;
  --color-dark-green: #27AE60;
  --color-bg-light-grey: #F6F6F6;
  --color-bg-grey: #EEEEEF;
}
