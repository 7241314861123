.statistic-block {
  width: 100%;
  padding: 24px 15px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__table {
    background: #fff;
    padding: 24px 15px;
    margin-top: 12px;
  }

  &__tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #d0d0d9;

  }

  &__th {
    padding: 8px 0;
    color: #070708;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 21.6px */

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  &__td {
    padding: 8px 0;
    color: #070708;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 21.6px */

    &:first-child {
      padding-left: 24px;
    }

    &:nth-child(2) {
      color: #0A49A5;
    }

    &:last-child {
      padding-right: 24px;
      color: #EF4062;;
    }
  }
}

.statistic-block-weight {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
}

.statistic-block-remove-line {
  border: none;
}
