div .react-datepicker  {
  background-color: var(--border-color2);
  border: 1px solid var(--border-color2);
  color: white;
  // width: 389px;
  padding: 0 10px;
  background: #FFFFFF;
  border: 1px solid #CFCFD2;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.11);
  border-radius: 14px;
}

.react-datepicker__month-container .react-datepicker__header {
  background: transparent;
  border-bottom: 0.5px solid #DDDCE9;
  padding-bottom: 16px;
}

.react-datepicker .react-datepicker__triangle {
  display: none;
}

.react-datepicker__month .react-datepicker__week .react-datepicker__day {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 2.7rem !important;
  text-align: center !important;
  color: #070708;
  width: 38px !important;
  height: 38px !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--main-color) !important;
  color: #fff !important;
  font-family: 'Jura';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  display: none;
}

.react-datepicker__day-name, .react-datepicker__time-name {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 25px !important;
  text-align: center !important;
  color: #070708 !important;
  margin-top: 16px !important;
}

.input-actions-custom, .input-icon-custom, .svg-custom {
  pointer-events: none;
}

.react-datepicker__month-dropdown-container .react-datepicker__month-select {
  background-color: transparent !important;
  border: transparent !important;
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 29px !important;
  text-align: center !important;
  color: #070708 !important;
  cursor: pointer !important;
  margin-left: -8px !important;
}

.react-datepicker__year-select {
  background-color: transparent !important;
  border: transparent !important;
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 29px !important;
  text-align: center !important;
  color: #070708 !important;
  cursor: pointer;
}

.react-datepicker__week .react-datepicker__day--keyboard-selected {
  background-color: var(--main-color) !important;
  color:#fff !important;
}

.react-datepicker__week .react-datepicker__day--keyboard-selected {
  &:hover {
    background-color: var(--main-color) !important;
    color:#fff !important;
  }
}

// select option {
//   margin: 40px;
//   background: var(--border-color2);
//   color: #fff;
//   text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
// }

.react-datepicker__navigation {
  top: 35px !important;
}

.react-datepicker__navigation--previous {
  left: 238px !important;
  top: 9px !important;
}

.react-datepicker__navigation--next {
  right: 18px !important;
  top: 9px !important;
}

.datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__month-dropdown-container--select {
  margin: 0 !important;
}

.react-datepicker__header__dropdown--select {
  display: flex !important;
}

.react-datepicker__day-name {
  width: 38px !important;
}

.input-item-width {
  border: none;
  background: transparent;
  width: 100%;
}
