.date-picker {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f7f9fc;
  border: 1px solid #ddd;
  border-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &__select {
    margin-bottom: 15px;
    label {
      font-size: 16px;
      font-weight: 500;
      margin-right: 10px;
    }

    select {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #ddd;
      background-color: #fff;
      transition: border 0.3s ease;

      &:focus {
        border-color: #4a90e2;
        outline: none;
      }
    }
  }
}
