@import '../../../assets/styles/custom/mixins';

.edit-track-wrap {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  padding: 16px;
  border-top: 1px solid var(--color-divider-grey, #CFCFD2);
}

.edit-track-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

}

.edit-track-text-black {
  color: var(--color-text-dark, #070708);
  text-align: center;
  /* Medium 14px */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.edit-track-text-gray {
  color: var(--color-text-gray, #929298);
  /* Caption 12px */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.edit-track {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;

  &__link {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: 24px 0;

    p {
      color: var(--color-border-blue, #0A49A5);
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 120% */
    }
  }
}

.metadata {
  margin: 24px 0;

  &__container {
    max-width: 645px;
    width: 100%;
    border-top: none;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    gap: 16px;
    padding: 16px;
    margin-bottom: 40px;
  }

  &__title {
    @include roboto(var(--text-primary-light), 14px, 500, 18px);
  }

  &__subtitle {
    @include roboto(var(--text-primary-light), 12px, 400, 14px);
  }

  &__wrap-drop {
    width: 100%;
  }

  &__add-key {
    // position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 94%;
      height: 1px;
      background-color: transparent;
    }
  }

  &__add-key-line {
    // position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   display: block;
    //   width: 94%;
    //   height: 1px;
    //   background-color: #CFCFD2;
    // }
  }

  &__add-padding-left {
    padding: 15px 0;
  }

  &__wrap-drop-bottom {
    margin-bottom: 40px;
  }
}

.form-metadata {
  &__group-input {
    position: relative;
    border-radius: 14px;
    background: var(--color-bg-grey);
    margin-top: 16px;
    padding: 0 20px;
  }

  &__group-input-padding {
    position: relative;
    border-radius: 14px;
    background: var(--color-bg-grey);
    margin-top: 16px;
    padding: 0;
  }

  &__group-input-album {
    position: relative;
    border-radius: 14px;
    background: var(--color-bg-grey);
    margin-top: 0;
    padding: 0 20px;
  }

  &__group-input-row {
    display: flex;
    gap: 16px;
    margin: 8px 0;
  }

  &__btn {
    margin-top: 40px;
  }
}

.padding {
  padding: 75px 0;
  margin: 0 auto;
  max-width: 448px;
}

.text-gray {
  color: #929298;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  padding: 22px 0 8px;

  span {
    color: red;
  }
}

.text-black {
  color: var(--color-text-dark, #070708);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding: 16px 0 8px;
}

.text-black-bolt {
  color: var(--color-text-dark, #070708);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Title 20px Medium */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;
  margin-top: 24px;
  }

.form-group {
  position: relative;
}

.select-wrap {
  position: relative;
  background: var(--color-bg-grey);
  border-radius: 14px;
}

.culendar-wrap {
  position: relative;
  background: var(--color-bg-grey);
  border-radius: 14px;
  margin: 16px 0;
  cursor: pointer;
}

.culendar-wrap-filter {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 14px;
  margin: 16px 0;
  cursor: pointer;
}

.culendar-svg-wrap {
  position: absolute;
  right: 15px;
  top: calc(50% - 10px);
}

.drop-wrap {
  padding: 8px;
  margin-bottom: 16px;
  height: 60px;
  border-radius: 12px;
  border: 1px dashed var(--color-button-dark-grey, #CFCFD2);
}

.drop-wrap-solid {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid var(--color-button-dark-grey, #CFCFD2);
  background: var(--color-button-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(6, 25, 56, 0.05);
  padding: 12px;
  width: 100%;
  height: 42px;
}

.file-uploader {
  display: flex;
  gap: 10px;
  align-items: center;

  color: var(--color-text-blue, #0A49A5);
  text-align: center;

  /* Medium 14px */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.button-blue-width {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 48px;
  margin: 0 auto;
}

.text-black-album {
  color: var(--color-text-dark, #070708);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 16px 0 0;
}

.border-marg {
  margin-top: 24px;
}

