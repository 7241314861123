.blur {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(177, 177, 181, 0.72);
  backdrop-filter: blur(4px);
}

.modal-wrap {
  position: relative;
  top: 25%;
  margin: 0 auto;
  max-width: 440px;
  min-width: 260px;
  width: 100%;
  border-radius: 10px;
  background: var(--bg-primary, #FFF);
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.08);
  padding: 40px;
  
  &__title {
    color: var(--text-primary-light, #070708);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    text-align: center;
  }

  &__sub-title {
    color: #74747B;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    text-align: center;
    margin-bottom: 24px;
  }

  &__btn {
    color: var(--bg-primary, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    border-radius: 18px;
    background: var(--button-primary, #0A49A5);
    text-align: center;
    padding: 10px 16px;
    width: 100%;
    margin-top: 24px;
  }
}