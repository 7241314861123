.sidebar-left {
  width: 360px;
  background: #FFFFFF;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.04));
  
  &__tabs-wrap {
    width: 100%;
    height: 64px;
    background: #F6F6F6;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #929298;
    position: relative;

    &:hover {
      color: #0A49A5;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        bottom: -19px;
        left: 0;
        width: 100%;
        height: 4px;
        background: #0A49A5;
        border-radius: 6px 6px 0px 0px;
      }
    }
  }

  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #929298;
    position: relative;
  }

  &__input {
    background: #EEEEEF;
    border-radius: 18px;
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 64px;
    padding: 0 16px;
  }

  &__btn-wrap {
    display: flex;
    gap: 16px;
  }

  &__text-gray {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #929298;

    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__info {
    display: flex;
    gap: 6px;
    padding: 0 16px;
  }

  &__number {
    display: block;
    background: #F0F6FF;
    border-radius: 10px;
    padding: 2px 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #0A49A5;
  }

  &__btn {
    padding: 16px;
    width: 65px;
  }

  &__request {
    position: relative;
    top: 32px;
    right: 16px;
    transform: rotate(-90deg);
  }
}

.active-tab {
  color: #0A49A5;

  &::after {
    content: "";
    position: absolute;
    bottom: -19px;
    left: 0;
    width: 100%;
    height: 4px;
    background: #0A49A5;
    border-radius: 6px 6px 0px 0px;
  }
}

.users {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 4px;

  &__list {
    overflow-x: auto;
    width: 100%;
    height: calc(100vh - 245px);

    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    // &::-webkit-scrollbar-track {
    //   background: #B1B1B5;
    //   border-radius: 12px;
    // }

    &::-webkit-scrollbar-thumb {
      background: #B1B1B5;
      border-radius: 30px;
      // border: 3px solid orange;  /* отступ вокруг бегунка */
    }
  }

  &__item {
    width: 100%;
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 10px 12px;
    border-radius: 12px;

    &:hover {
      background: #0A49A5;
      color: #FFFFFF;
    }
  }

  &__ava-wrap {
    display: flex;
    gap: 16px;

    img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__text-regular {
      color: #FFFFFF;
    }
  }

  &__text-bolt {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__text-regular {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #929298;
    white-space: nowrap;
    // text-align: right;
  }

  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 4px;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 10px;

    &--0 {
      background: #04B505;
    }

    &--1 {
      background: #8809F6;
    }

    &--2 {
      background: #B1B1B5;
    }
  }
}

.button-active {
  background: #0A49A5;
  color: #FFFFFF;
}

.county-lan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  height: 24px;

  &__name {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      color: var(--color-text-dark);
      font-size: 15px;
      font-family: Roboto;
      line-height: 18px;
    }
  }

  &__button {
    display: flex;
    width: 317px;
    padding: 16px 120px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 10px;
    background: var(--color-button-blue);
    margin: 0 16px 0 11px;

    color: var(--color-text-white);
    text-align: center;
    font-size: 13px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 15px;
  }
  img {
    width: 20px;
  }
}

.county-lan-height {
  height: calc(100vh - 290px);
}

.filter-user {
  padding: 0 11px;
}

.culendar-wrap {
  display: flex;
  gap: 3px;
}

.select-wrap {
  position: relative;
  z-index: 0;
  margin-top: 16px;

  &--zindex {
    z-index: 2;
  }
}

.culendar-wrap {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}

.react-datepicker-wrapper {
  width: 155px;
}

.sidebar-left-width {
  transition: width 0.4s;
  width: 65px;
}

.culendar-wrap-width .react-datepicker-wrapper {
  width: 100%;
}
