.wrapper-checkbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.check__input {
	position: absolute;
	left: -99999999px;
}
.check__label {
  display: flex;
  align-items: center;
	vertical-align: middle;
}
.check__label::before {
	content: '';
	display: inline-block;
	min-width: 16px;
	max-width: 16px;
	height: 16px;
	padding-bottom: 0.05em;
	font-size: 0.7em;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
	vertical-align: middle;
	margin-right: 8px;
	text-align: center;
	position: relative;
	// top: -0.2em;
}
.check__input:checked + .check__label::before {
	content: '\2714';
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.form-sms {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #B1B1B5;
  margin-top: -10px;
  margin-bottom: 20px;
}

.password-strength .red {
  background: #EF4062;
}

.password-strength .yellow {
  background: #F2994A;
}