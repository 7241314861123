.ai-income {
  padding: 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      color: #070708;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }

  &__checkbox-inner {
    display: flex;
    gap: 24px;
    padding: 24px 0;
  }

  &__img {
    width: 100%;
    max-width: 100%;
  }

  &__td {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.border-top {
  border-top: 1px solid var(--ui-border-color);
}

.table-header .tr-remove-border-top {
  border-top: 1px solid transparent;
  min-height: auto;
}

.ai-title {
  color: var(--text-primary-light, #070708);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}

.ai-blue {
  color: var(--text-link, #0A49A5);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.ai-red {
  color: #EF4062;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.ai-td-title {
  color: #070708;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.ai-td-blue {
  color: var(--text-link, #0A49A5);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.ai-td-red {
  color: #EF4062;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
