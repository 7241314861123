.card-finance {
  padding: 32px ;
  display: flex;
  justify-content: center;
  gap: 8px;

  &__box {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #EEEEEF;
    background: #FFF;
    gap: 14px;
  }

  &__text-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__btn-wrap {
    padding-top: 16px;
    color: var(--color-text-white);
    font-size: 13px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 15px;
  }

  &__line {
    padding-bottom: 16px;
    margin-bottom: 2px;
    border-bottom: 1px solid #D9D9D9;
  }
}