.details-withdrawal {
  &__row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 24px;
  }

  &__row-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
  }

  span {
    color: var(--text-primary-light, #070708);
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
}

.line-bottom {
  border-bottom: 1px solid #CFCFD2;
  padding-bottom: 16px;
  margin-bottom: 6px;
  padding-top: 6px;
  height: auto;

}