.reactions-add {
  padding: 24px 15px;
  margin: 16px 0;
  background: var(--color-text-white);

  &__information {
    padding: 24px 30px;
    margin-bottom: 4px;
    border-radius: 15px;
    background: var(--background-gray);
    height: 78px;
    overflow: hidden;
  }

  &__information-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    &:last-child {
      cursor: pointer;
    }
  }

  &__information-title {
    color: var(--color-text-dark);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__input-name {
    color: var(--color-text-dark);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__input {
    width: 100%;
    max-width: 600px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid var(--line-line-light);
    background: var(--color-text-white);
  }

  &__delete {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }
}

.active {
  height: max-content;
}

.reactions-add__input-wrapper .background__wrapper-drop {
  grid-template-columns: 1fr;
  width: 70%;
  margin-top: 0;
}

.reactions-add__input-wrapper .background__img-wrap {
  height: auto;
}

.reactions-add__input-wrapper .background__img-svg {
  margin: 0 auto;
  left: 13px;
}

.background__img .background__img-svg svg {
  position: relative;
  left: 33px;
  top: -15px;
}

.reactions-add__input-wrapper .background__img {
  position: relative;
  // top: 20px;
  width: 120px;
}

.reactions-add__input-wrapper .background__text-gray {
  width: max-content;
  max-width: max-content;
}

.background__img {
  label {
    position: absolute;
  }
}

.background__inner-drop .background__title {
  margin-top: 20px;
}
