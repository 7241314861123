.user-data {
  padding: 15px;

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 768px) {
      display: flex;
    }

    @media screen and (max-width: 525px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  &__block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 24px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #070708;

    @media screen and (max-width: 372px) {
      gap: 8px;
    }
  }

  &__toggle-wrapper {
    display: flex;
    align-items: center;

    @media screen and (max-width: 353px) {
      gap: 8px;
      font-size: 12px;
    }

    @media screen and (max-width: 329px) {
      gap: 8px;
      font-size: 11px;
    }
  }

  &__toggle-text {
    margin-right: 8px;
    white-space: nowrap;
  }

  &__toggle-text-custom {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;

    @media screen and (max-width: 478px) {
      font-size: 14px;
    }
  }

  &__card-wrapper {
    display: flex;
    gap: 8px;
    margin: 24px 0 16px;

    @media screen and (max-width: 768px) {
     flex-wrap: wrap;
    }
  }

  &__card {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    border-radius: 12px;
    padding: 16px 24px;

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: 469px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #070708;
  }

  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #0A49A5;
  }

  &__details-user {
    border: 1px solid #EEEEEF;
    border-radius: 12px;
    padding: 24px;
  }

  &__placeholder {
    width: 60% !important;
    border: 1px solid #CFCFD2;
    border-radius: 12px;
    padding: 10px;

    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }

  &__footer-title {
    margin: 25px 0 8px;
  }

  &__button-wrapper {
    width: 60%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &__button {
    width: 69px;
    height: 54px;
    background: #0A49A5;
    border-radius: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #FFFFFF;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #070708;
    margin-bottom: 8px;
  }
}

.details-user {
  display: flex;
  gap: 29px;

  &__box-text {
    width: 60%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &__box-text-custom {
    @media screen and (max-width: 1068px) {
      width: 100%;
    }
  }

  &__link {
    color: #0A49A5;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 16px;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #070708;
    }
  }

  &__item-upper {
    text-transform: capitalize;
  }

  &__item2 {
    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #070708;
    }
  }

  &__img-wrapper {
    justify-content: flex-end;
    width: 40%;
    display: flex;

    @media screen and (max-width: 1024px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__img-wrapper-custom {
    @media screen and (max-width: 1068px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.box-photo {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: max-content;
  height: max-content;
  justify-content: flex-end;
  cursor: pointer;

  &__inner {
    position: relative;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    max-width: 192px;
    max-height: 192px;
    overflow: hidden;
  }

  &__inner-photo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    border-radius: 12px;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }
}

.box-photo-one {
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;

  &__inner {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.max-width {
  max-width: 220px;
  min-width: 200px;
}

.max-width100 {
  max-width: 100%;
  min-width: 200px;
}

.max-width-persent {
  @media screen and (max-width: 573px) {
    max-width: 100%;
  }
}

// .tabs-buttons-nav {
//   @media screen and (max-width: 768px) {
//     flex-wrap: nowrap;
//   }
// }

.pointer .red {
  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 525px) {
    display: block;
  }
}

.pointer .show {
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.right {
  display: flex;
  justify-content: flex-end;
  width: fit-content;

  @media screen and (max-width: 525px) {
    justify-content: center;
    margin-top: 20px;
  }
}

.left-custom {
  @media screen and (max-width: 525px) {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.left {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
}

.user-data__block .left {
  @media screen and (max-width: 431px) {
    display: flex;
    flex-direction: column;
  }
}

.width {
  width: 100%;
}

.red {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EF4062;
}

.width .right {
  gap: 8px;
}
.pointer {
  cursor: pointer;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--main-color);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--main-color);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.user-data-custom {
  padding-top: 1px;
}

.width-user-name-btn {
  min-width: 190px;
}