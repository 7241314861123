.comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 15px 0;

  &__header-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #070708;
  }

  &__filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: url(../../svg-icon/background.svg);
    background-size: cover;
    border-radius: 12px;
    padding: 15px 20px;
  }

  &__item {
    display: flex;
    align-items: flex-end;
  }

  &__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #070708;
  }

  &__wrapper {
    width: 100%;
  }

  &__matrix {
    position: absolute;
    bottom: 0;
    left: -8px;
    width: 16px;
    height: 20px;
    background: #FFFFFF;
    transform: matrix(-1, 0, -1, 1, 10, 0);
  }

  &__text-wrapper {
    display: flex;
    gap: 17px;
    max-width: 378px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 8px;
    position: relative;
    margin-left: 15px;
  }

  &__time {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #929298;
    margin-top: 12px;
  }

  &__img {
    width: 35px;
    height: 35px;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    margin-right: 20px;
  }

  &__icon {
    display: flex;
    justify-content: space-between;
    align-self: start;
    width: 42px;
    padding-top: 10px;
    margin-right: 10px;

    span {
      width: 15px;
    }
  }

  &__name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #EF4062;
    margin-bottom: 11px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
}

.input .input-wrapper-custom {
  margin: 0;
}

.input-item--left-icon-none {
  padding-left: 12px;
}