.ai-management {
  padding-bottom: 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 15px;

    > span {
      text-transform: capitalize;
      color: #070708;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }

  &__body {
    border-radius: 15px;
    background: #F7F7F8;
    padding: 30px;
    margin: 0 15px;
  }

  &__body-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    &--padding {
      padding: 20px 0 16px;
      border-top: 1px solid #D9D9D9;
      margin-top: 24px;
    }

    > span {
      color: #070708;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }

  &__inputs-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input-wrap {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }

  &__input-name {
    color: #070708;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__input {
    outline: none;
    height: 40px;
    border-radius: 12px;
    border: 1px solid #CFCFD2;
    background: #FFF;
    max-width: 260px;
    width: 100%;
    padding: 8px 12px 7px 12px;
  }

  &__subtitle {
    color: #070708;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }

  &__btn {
    color: #0A49A5;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  &__chackbox {
    padding: 16px 0;
  }
}

.ai-bot-list {
  display: flex;
  flex-direction: column;
}

.ai-bot-item {
  padding: 10px 16px;
}

.ai-bot-wrap {
  padding: 0;
}

.creat-bot-title {
  padding: 0;
}

.code-generation .input-item--left-icon-none {
  margin-bottom: 25px;
}
