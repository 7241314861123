.filter-custom {
  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 180px;
    // height: 40px;
    border: 1px solid #CFCFD2;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__btn-name {
    display: flex;

    span {
      margin-left: 8px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #070708;
    }
  }

  &__country-wrapper {
    max-width: 380px;

    @media screen and (max-width: 632px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__flex {
    display: flex;
    border-top: 1px solid #CFCFD2;
    justify-content: space-between;
    padding-top: 25px;

    @media screen and (max-width: 632px) {
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }

  &__info-block {
    display: grid;
    grid-template-columns: 1fr 1fr 63px;
    // overflow: hidden;
    margin-top: 16px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__header {
    grid-template-columns: 1fr 1fr 63px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__btn-save {
    padding: 10px 16px;
    margin-bottom: 16px;
    width: 63px;
    height: 44px;
    text-align: center;
    background: #0A49A5;
    border-radius: 12px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    color: #FFFFFF;

    align-self: flex-end;
  }

  &__title-table {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    display: flex;
    align-items: center;
    color: #070708;
  }

  &__check-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;

    @media screen and (max-width: 494px) {
      grid-template-columns: 1fr;
    }
  }

  &__info-card {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 0 15px 16px;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__card {
    padding: 16px 24px;
    width: 100%;
    // max-width: 205.6px;
    height: 90px;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    border-radius: 12px;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #070708;
  }

  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #0A49A5;
  }
}

.select-custom-width {
  max-width: 380px;
}

.none {
  display: none;
}

.active-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}