.checkbox {
	margin: 0 0 10px 0;
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 16px;
		width: 16px;
		height: 16px;
		border: 1px solid var(--ui-checkbox-border);
		border-radius: 5px;
		cursor: pointer;
	}
	&__item-icon {
		display: flex;
		width: 10px;
		opacity: 0;
		.fill path {
			fill: #fff;
		}
	}
	&__label {
		display: flex;
		align-items: center;
	}
	&__text {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		a {
			color: blue;
			text-decoration: underline !important;
		}
	}
	input:checked ~ .checkbox__item {
		background: var(--ui-checkbox-bg);
		border-color: var(--ui-checkbox-bg);
		.checkbox__item-icon {
			opacity: 1;
		}
	}

	input:disabled ~ .checkbox__item {
		cursor: default;
		background: #b6b6b6;
		& + .checkbox__text {
			cursor: default;
		}
	}

	&--no-mb {
		margin-bottom: 0;
	}
}

.checkbox-two {
	margin: 0 0 10px 0;
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 16px;
		width: 16px;
		height: 16px;
		border: 1px solid var(--ui-checkbox-border);
		border-radius: 5px;
		cursor: pointer;
	}
	&__item-icon {
		display: flex;
		width: 10px;
		opacity: 0;
		.fill path {
			fill: #fff;
		}
	}
	&__label {
		display: flex;
		align-items: center;
	}
	&__text {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		a {
			color: blue;
			text-decoration: underline !important;
		}
	}
	input:checked ~ .checkbox-two__item {
		border-color: var(--ui-checkbox-bg);
		.checkbox-two__item-icon {
			opacity: 1;
		}
	}

	input:disabled ~ .checkbox-two__item {
		cursor: default;
		background: #b6b6b6;
		& + .checkbox-two__text {
			cursor: default;
		}
	}

	&--no-mb {
		margin-bottom: 0;
	}

	&__photo {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		margin-left: 10px;
	}
}

.checkbox-one {
	margin: 0 0 10px 0;
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 24px;
		width: 24px;
		height: 24px;
		border: 1px solid var(--ui-checkbox-border);
		border-radius: 5px;
		cursor: pointer;
	}
	&__item-icon {
		display: flex;
		width: 10px;
		opacity: 0;
		.fill path {
			fill: #fff;
		}
	}
	&__label {
		display: flex;
		align-items: center;
	}
	&__text {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		a {
			color: blue;
			text-decoration: underline !important;
		}
	}
	input:checked ~ .checkbox-one__item {
		border-color: var(--color-checkbox-gray);
		.checkbox-one__item-icon {
			opacity: 1;
		}
	}

	input:disabled ~ .checkbox-one__item {
		cursor: default;
		background: #b6b6b6;
		& + .checkbox-one__text {
			cursor: default;
		}
	}

	input:checked ~ .checkbox-one__item-blue {
		border-color: var(--color-button-blue);
		.checkbox-one__item-icon {
			opacity: 1;
		}
	}

	input:disabled ~ .checkbox-one__item-blue {
		cursor: default;
		background: #0A49A5;
		& + .checkbox-one__text {
			cursor: default;
		}
	}

	&--no-mb {
		margin-bottom: 0;
	}

	&__photo {
		display: flex;
		align-items: center;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		margin-left: 10px;
		overflow: hidden;

		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.round {
	border-radius: 50%;
}

.small {
	border-radius: 50%;
	border-color: var(--ui-checkbox-bg) !important;
	background-color: var(--ui-checkbox-bg) !important;
	width: 8px !important;
	height: 8px !important;
}

.small-oval {
	border-radius: 3px !important;
	border-color: var(--color-checkbox-gray) !important;
	background-color: var(--color-checkbox-gray) !important;
	width: 18px !important;
	height:18px !important;
}

.small-full {
	border-radius: 3px !important;
	border-color: var(--color-button-blue) !important;
	background-color: var(--color-button-blue) !important;
	width: 100% !important;
	height: 100% !important;
}

.checkbox-three {
	padding-bottom: 15px;
}

.code-generation-btn .code-generation {
  overflow-y: scroll;
	max-height: 300px;
	margin: 25px 0;

/* Стилизация скроллбара */
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

/* Стилизация трека (фона) скроллбара */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

/* Стилизация ползунка скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

/* Стилизация ползунка при наведении */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

/* Стилизация стрелок скроллбара (если есть) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}
