.user-partner-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.user-partner-text {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  max-width: 600px;
}

.add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.add-btn {
  display: flex;
  // height: 54px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #0A49A5;

  color: #FFF;

  /* Medium/P3 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 28.8px */
}

.discount-popup {
  max-width: 340px;

  &__title {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  &__subtitle {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    padding: 10px 0 8px;
  }

  &__text {
    color: var(--Color-Text-Dark, #070708);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 10px 0;
  }

  &__referal-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: #EEEEEF;
  }

  &__referal-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
  }

  &__referal-link-text {
    color: #070708;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__referal-code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #EEEEEF;
    border-radius: 12px;
    margin-top: 10px;
    padding: 18px;
  }

  &__referal-link-text-gray {
    color: var(--Color-Text-Gray, #929298);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 8px;
  }

  &__svg {
    cursor: pointer;
  }
}