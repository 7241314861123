.modal {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(177, 177, 181, 0.72);
  backdrop-filter: blur(4px);

  &__title {
    text-align: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 180%;
    color: var(--color-text-dark);
    margin-bottom: 16px;
  }

  &__wrap {
    padding: 24px;
    border-radius: 10px;
    background: var(--color-text-white);
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.08);
    max-width: 420px;
    width: 100%;
  }

  &__list {
    margin-top: 26px;
  }

  &__btn-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 16px;
  }

  &__subtitle {
    color: #74747B;
    text-align: center;
    font-size: 14px;
    font-family: Roboto;
    line-height: 120%;
  }
}

.input-amount {
  margin: 8px 0 24px;
}

.button-margin {
  margin-bottom: 16px;
}
