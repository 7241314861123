.countries-list {
  display: flex;
  cursor: pointer;
  button {
    img {
      height: 25px;
      width: 25px;
      align-self: center;
      margin-right: 15px;
    }

    
    &:hover {
      background: #F7F7F7 !important;
      border-radius: 0 !important;
    }
  }

  svg {
    width: max-content;
  }

  &:hover {
    background: #F7F7F7 !important;
    border-radius: 0 !important;
    width: 100%;
  }
}

.country-placeholder {
  color: #515151;
}

.select__drop-custom {
  background: white;
  width: max-content;
}

.select-custom {
  width: 100%;
}
