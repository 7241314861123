.edit-main {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &__select {
    display: flex;
    gap: 10px;
  }

  &__btn-remove {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: #EF4062;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 25.6px */
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 10px;
    gap: 16px;
  }

  &__input-wrp {

  }

  &__label {
    color: var(--Text-Primary_Light, #070708);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    margin-bottom: 8px;
  }

  &__input {
    display: flex;
    padding: 8px 12px;
    width: 100%;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 12px;
    border: 1px solid #CFCFD2;

    &::placeholder {
      color: var(--Text-Placeholder_Light, #858585);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
  }
}